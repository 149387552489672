/* MessageInput.css */
.message-input-form {
  display: flex;
  width: 100%;
  margin-top: 16px;
}

.message-input {
  display: flex;
  width: 100%;
}

.message-input-field {
  flex-grow: 1;
}

.message-send-button {
  margin-left: 16px;
}
