/* src/components/auth/ResetPassword.css */
.reset-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa; /* Light gray background */
  }
  
  .reset-password-box {
    width: 100%;
    max-width: 400px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .reset-password-box h2 {
    margin-bottom: 20px;
    font-size: 24px;
    text-align: center;
  }
  
  .reset-password-box .form-group {
    margin-bottom: 15px;
  }
  
  .reset-password-box .form-control {
    height: 40px;
    padding: 10px;
    font-size: 16px;
  }
  
  .reset-password-box .btn {
    font-size: 16px;
    padding: 10px;
    width: 100%;
    margin-top: 10px;
  }
  
  .reset-password-box .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .reset-password-box .btn-primary:hover {
    background-color: #0056b3;
    border-color: #004085;
  }
  
  .reset-password-box .text-center {
    margin-top: 15px;
  }
  
  .reset-password-box .text-center a {
    color: #007bff;
    text-decoration: none;
  }
  
  .reset-password-box .text-center a:hover {
    text-decoration: underline;
  }
  