/* src/pages/HomePage.css */
.home-container {
  padding: 20px;
  margin-top: 60px; /* Adjust this value if needed to create space from the navigation */
  display: flex;
  justify-content: center;
}

.home-box {
  padding: 20px; /* Adjusted padding to reduce height */
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 900px; /* Added max-width to limit the container's width */
  width: 100%;
}

.home-title {
  font-size: 1rem; /* Adjusted font size to reduce height */
  font-weight: bold;
  color: #333;
  margin: 5px 0; /* Reduced margin to reduce height */
}

.home-description {
  font-size: 0.8rem; /* Adjusted font size to reduce height */
  color: #666;
  margin: 5px auto; /* Reduced margin to reduce height */
}

.home-button {
  text-transform: none;
  padding: 5px 10px; /* Adjusted padding to reduce height */
  font-size: 0.8rem; /* Adjusted font size to reduce height */
  border-radius: 20px;
}

.home-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

@media (min-width: 600px) {
  .home-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .home-box img {
    flex: 1;
  }

  .home-box .text-container {
    flex: 1;
    padding-left: 10px; /* Adjusted padding to reduce height */
  }
}
