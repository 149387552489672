.register-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  padding-top: 50px;
}

.register-box {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px; /* Adjust as needed */
  width: 100%;
}

.button-container {
  margin-top: 1rem;
  width: 100%;
}

.register-button, .google-register-button {
  width: 100%; /* Full width button */
  padding: 1rem; /* Ensure consistent padding */
  font-size: 1rem; /* Ensure consistent font size */
}

.text-center {
  text-align: center;
}

.mt-3 {
  margin-top: 1rem;
}

.grid-item {
  padding: 0.5rem; /* Added padding for better spacing */
}
