/* General Container Styles */
.about-container {
  padding: 20px;
  margin-top: 60px; /* Adjust this value if needed to create space from the navigation */
  display: flex;
  justify-content: center;
}

/* Box Styling */
.about-box {
  padding: 20px; /* Adjusted padding to give more space inside the box */
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 800px; /* Added max-width to limit the container's width */
  width: 100%;
}

/* Title Styling */
.about-title {
  font-size: 2.5rem; /* Increased font size for a more modern look */
  font-weight: bold;
  color: #333;
  margin: 10px 0; /* Adjusted margin for better spacing */
}

/* Description Styling */
.about-description {
  font-size: 1.2rem; /* Adjusted font size for readability */
  color: #666;
  margin: 10px auto; /* Adjusted margin for better spacing */
}

/* Text Styling */
.about-text, .MuiTypography-root.MuiTypography-body1.about-text {
  font-size: 1rem; /* Adjusted font size for readability */
  padding: 0 20px; /* Added padding for better text alignment */
  color: #555;
  line-height: 1.6;
  margin-top: 15px; /* Adjusted margin to add space between text and images */
}

/* Content Styling */
.about-content {
  margin-top: 25px; /* Added margin-top for better spacing between sections */
}

/* Image Styling */
.about-image {
  width: 100%;
  height: auto;
  margin-top: 20px;
  border-radius: 8px; /* Added border-radius for a more polished look */
}
