/* src/components/layout/Footer.css */

.footer {
    background-color: #f8f9fa;
    padding: 1rem 0;
    width: 100%;
    border-top: 1px solid #e7e7e7;
  }
  
  .footer p {
    margin: 0;
    color: #6c757d;
  }
  