/* src/pages/ChatPage.css */
.chat-page-container {
  margin-top: 20px;
}

.header {
  margin-bottom: 20px; /* Add margin to create space below the header */
}

.chat-window-container {
  margin-top: 20px; /* Add margin-top to create space above the chat window */
}

.chat-window {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.chat-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  background-color: #f9f9f9;
}

.message-list {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 10px;
}

.message-input {
  display: flex;
  align-items: center;
}
