/* src/components/ContactForm/Contact.css */
.contact-container {
  padding: 20px;
  margin-top: 60px; /* Adjust this value if needed to create space from the navigation */
  display: flex;
  justify-content: center;
}

.contact-box {
  padding: 20px; /* Adjusted padding to give more space inside the box */
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 800px; /* Added max-width to limit the container's width */
  width: 100%;
}

.contact-title {
  font-size: 2.5rem; /* Increased font size for a more modern look */
  font-weight: bold;
  color: #333;
  margin: 10px 0; /* Adjusted margin for better spacing */
}

.contact-description {
  font-size: 1.2rem; /* Adjusted font size for readability */
  color: #666;
  margin: 10px auto; /* Adjusted margin for better spacing */
}

.contact-text {
  font-size: 1rem; /* Adjusted font size for readability */
  color: #555;
  line-height: 1.6;
  margin-top: 10px; /* Adjusted margin for better spacing */
}

.contact-content {
  margin-top: 20px; /* Added margin-top for better spacing between sections */
}

/* Position the reCAPTCHA badge */
.grecaptcha-badge {
  z-index: 100; /* Ensure it is above other elements */
}
