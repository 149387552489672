/* src/pages/LoginPage.css */

.login-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;
    padding-top: 50px; /* Change padding to 50px */
  }
  
  .login-page-box {
    width: 100%;
    max-width: 500px;
  }
  