/* Message.css */
.message {
  display: flex;
  align-items: center;
  margin: 8px 0;
}

.user-avatar {
  background-color: #606060;
  color: #cfcfcf;
}

.bot-avatar {
  background-color: #e91e63;
  color: #ffffff;
}

.role-name {
  font-weight: bold;
  color: #888888;
}

.message-content {
  white-space: pre-wrap;
  word-wrap: break-word;
  color: #ffffff;
}
