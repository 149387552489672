/* Navbar Styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #f8f9fa;
  border-bottom: 1px solid #ddd;
}

.navbar-left,
.navbar-right {
  display: flex;
  align-items: center;
}

.navbar-logo {
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 2rem;
  text-decoration: none;
  color: #000;
}

.navbar-link {
  margin-right: 1rem;
  text-decoration: none;
  color: #000;
  font-size: 1rem;
}

.navbar-link:last-child {
  margin-right: 0;
}

/* Layout and Content Styles */
.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
  padding: 2rem;
  padding-top: 20px; /* Adjust according to your Navbar height */
}

/* Footer Styles */
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: #f8f9fa;
  border-top: 1px solid #ddd;
  width: 100%;
  margin-top: auto; /* Ensure the footer is pushed to the bottom */
}

.footer-content {
  text-align: center;
}
