/* src/components/layout/Navbar.css */

.navbar {
    background-color: #f8f9fa;
    padding: 1rem 2rem;
  }
  
  .navbar-brand {
    font-size: 1.5rem;
    font-weight: bold;
    color: #000;
  }
  
  .navbar-brand:hover {
    color: #000;
    text-decoration: none;
  }
  
  .navbar-nav .nav-link {
    font-size: 1rem;
    color: #555;
    padding: 0.5rem 1rem;
    transition: color 0.3s ease;
  }
  
  .navbar-nav .nav-link:hover {
    color: #000;
  }
  
  .navbar-nav .nav-link.active {
    color: #000;
    font-weight: bold;
  }
  
  @media (max-width: 991.98px) {
    .navbar-nav .nav-link {
      padding: 0.5rem 0;
    }
  }
  